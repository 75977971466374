import {
  Button,
  Col,
  Modal,
  Row,
  Form as BForm,
  Spinner,
} from '@themesberg/react-bootstrap';
import { ErrorMessage, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { bannerType, pageNameOptions } from './constants.js';
import { uploadBannerSchema } from './schema.js';
import Datetime from 'react-datetime';
import { DateFormatter, getDateTime, getDateTimeByYMD} from '../../utils/dateFormatter.js';
import toast from 'react-hot-toast';

const EditUploadBanner = ({
  t,
  type,
  data,
  show,
  setShow,
  createUpdate,
  loading,
  bannersList,
}) => {
  const [image, setImage] = useState(null);
  const [startDateRequired, setStartDateRequired] = useState(false);
  const [endDateRequired, setEndDateRequired] = useState(false);
  const [pageNameData, setPageNameData] = useState([]);
  const [mobileDimension, setMobileDimension] = useState(false);
  const yesterday = new Date(Date.now() - 86400000);
  const [desktopDimension, setDesktopDimension] = useState(false);
  const handleFileChange = (event, setFieldValue, field) => {
    const file = event.target.files[0];
    setImage(file);

    // Validate file dimensions
    validateFileDimensions(file, field);

    // Optionally, you can update form field value
    setFieldValue(field, file);
  };

  const validateFileDimensions = (file, field) => {
    if (file && field === 'mobileImage') {
      const img = new Image();
      img.onload = function () {
        if (img.width > 684 || img.height > 280) {
          setMobileDimension(true)
          // alert('Image dimensions must be less than or equal to 100x100 pixels.');
          // Clear the file input
          setImage(null);
        }
        else {
          setMobileDimension(false)
        }
      };
      img.src = URL.createObjectURL(file);
    }
    else {
      const img = new Image();
      img.onload = function () {
        if (img.width > 1200 || img.height > 318) {
          setDesktopDimension(true)
          // alert('Image dimensions must be less than or equal to 100x100 pixels.');
          // Clear the file input
          setImage(null);
        }
        else {
          setDesktopDimension(false)
        }
      };
      img.src = URL.createObjectURL(file);
    }
  };
  const handleCheckoutChange = (e, setFieldValue) => {
    if (e.target.checked == true) {
      setFieldValue('isCountDown', e.target.checked);
      setStartDateRequired(true)
      setEndDateRequired(true)
    } else {
      setFieldValue('isCountDown', e.target.checked);
      setStartDateRequired(false)
      setEndDateRequired(false)
    }
  }
  useEffect(() => {
    let permanentData = [{ label: 'casinoBannerManagement.constant.lobbySlider', value: 'lobbySlider' }, { label: 'casinoBannerManagement.constant.bonusPromotionPage', value: 'bonusPromotionPage' },]
    let data = pageNameOptions?.filter(y => !(bannersList?.rows?.find(x => x.pageName == y.value)))
    setPageNameData([...data, ...permanentData])
  }, [bannersList])
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            {type} {t('casinoBannerManagement.uploadBanner.title')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={{
              mobileImage: null,
              desktopImage: null,
              isActive: true,
              pageBannerId: data?.pageBannerId || '',
              visibility: 2,
              pageName: data?.pageName || '',
              textOne: data?.textOne || '',
              textTwo: data?.textTwo || '',
              textThree: data?.textThree || '',
              btnText: data?.btnText || '',
              btnRedirection: data?.btnRedirection || '',
              name: data?.name || '',
              isCountDown: data?.isCountDown || false,
              startDate: data?.startDate ? DateFormatter(data?.startDate) : '',
              endDate: data?.endDate ? DateFormatter(data?.endDate) : ''
            }}
            validationSchema={uploadBannerSchema(type, t)}
            onSubmit={(formValues) => {
              if (formValues.isCountDown == true && (formValues.startDate == '' || formValues.endDate == '')) {
                toast('Please check FormData', 'error');
                if(formValues.startDate == '') setStartDateRequired(true)
                if( formValues.endDate == '') setEndDateRequired(true)
              } else {
                const data = {
                  isActive: true,
                  visibility: 2,
                  pageName: formValues.pageName,
                  textOne: formValues.textOne,
                  textTwo: formValues.textTwo,
                  textThree: formValues.textThree,
                  btnText: formValues.btnText,
                  btnRedirection: formValues.btnRedirection,
                  name: formValues.name,
                  isCountDown: formValues.isCountDown,
                  startDate: formValues.isCountDown === true ? getDateTime(formValues.startDate) : null,
                  endDate:  formValues.isCountDown === true ? getDateTime(formValues.endDate) : null
                };
                if (formValues.mobileImage)
                  data.mobileImage = formValues.mobileImage;
                if (formValues.desktopImage)
                  data.desktopImage = formValues.desktopImage;
                if (formValues.pageBannerId)
                  data.pageBannerId = formValues.pageBannerId;
                createUpdate(data);
              }
            }}
          >
            {({
              values,
              errors,
              handleSubmit,
              handleBlur,
              setFieldValue,
              handleChange,
            }) => {
              
            return   (
                <Form>
                  <Row className='mt-3'>
                    <Col className='d-flex'>
                      <BForm.Label>
                        {t('casinoBannerManagement.inputField.pageName.label')}{' '}
                        <span className='text-danger'>*</span>
                      </BForm.Label>
                    </Col>
  
                    <Col xs={9}>
                      <BForm.Select
                        type='select'
                        name='pageName'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pageName}
                        disabled={data?.pageBannerId}
                      >
                        <option value='' disabled>
                          ---
                          {t(
                            'casinoBannerManagement.constant.selectPageName'
                          )}
                          ---
                        </option>
                        {data?.pageName ? <><option value={data.pageName}>{data?.pageName}</option></> :
                          <>
                            {
                              pageNameData?.map((info, index) =>
                                <option key={index} value={info.value}>{t(info.label)}</option>
                              )
                            }
                          </>
                        }
                      </BForm.Select>
                      <ErrorMessage
                        component='div'
                        name='pageName'
                        className='text-danger'
                      />
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col className='d-flex'>
                      <BForm.Label>
                        {t('casinoBannerManagement.inputField.name.label')}
                        <span className='text-danger'>*</span>
                      </BForm.Label>
                    </Col>
  
                    <Col xs={9}>
                      <BForm.Control
                        type='text'
                        name='name'
                        placeholder={t(
                          'casinoBannerManagement.inputField.name.placeholder'
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        disabled={data?.pageBannerId}
                      />
                      <ErrorMessage
                        component='div'
                        name='name'
                        className='text-danger'
                      />
                    </Col>
                  </Row>
  
                  <Row className='mt-3'>
                    <Col className='d-flex'>
                      <BForm.Label>
                        {t('casinoBannerManagement.inputField.textOne.label')}
                      </BForm.Label>
                    </Col>
  
                    <Col xs={9}>
                      <BForm.Control
                        type='text'
                        name='textOne'
                        placeholder={t(
                          'casinoBannerManagement.inputField.textOne.placeholder'
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.textOne}
                      />
                      <ErrorMessage
                        component='div'
                        name='textOne'
                        className='text-danger'
                      />
                    </Col>
                  </Row>
  
                  <Row className='mt-3'>
                    <Col className='d-flex'>
                      <BForm.Label>
                        {t('casinoBannerManagement.inputField.textTwo.label')}
                      </BForm.Label>
                    </Col>
  
                    <Col xs={9}>
                      <BForm.Control
                        type='text'
                        name='textTwo'
                        placeholder={t(
                          'casinoBannerManagement.inputField.textTwo.placeholder'
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.textTwo}
                      />
                      <ErrorMessage
                        component='div'
                        name='textTwo'
                        className='text-danger'
                      />
                    </Col>
                  </Row>
  
                  <Row className='mt-3'>
                    <Col className='d-flex'>
                      <BForm.Label>
                        {t('casinoBannerManagement.inputField.textThree.label')}
                      </BForm.Label>
                    </Col>
  
                    <Col xs={9}>
                      <BForm.Control
                        type='text'
                        name='textThree'
                        placeholder={t(
                          'casinoBannerManagement.inputField.textThree.placeholder'
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.textThree}
                      />
                      <ErrorMessage
                        component='div'
                        name='textThree'
                        className='text-danger'
                      />
                    </Col>
                  </Row>
  
                  <Row className='mt-3'>
                    <Col className='d-flex'>
                      <BForm.Label>
                        {t('casinoBannerManagement.inputField.btnText.label')}
                      </BForm.Label>
                    </Col>
  
                    <Col xs={9}>
                      <BForm.Control
                        type='text'
                        name='btnText'
                        placeholder={t(
                          'casinoBannerManagement.inputField.btnText.placeholder'
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.btnText}
                      />
                      <ErrorMessage
                        component='div'
                        name='btnText'
                        className='text-danger'
                      />
                    </Col>
                  </Row>
  
                  <Row className='mt-3'>
                    <Col className='d-flex'>
                      <BForm.Label>
                        {t(
                          'casinoBannerManagement.inputField.btnRedirection.label'
                        )}
                      </BForm.Label>
                    </Col>
  
                    <Col xs={9}>
                      <BForm.Select
                        ype='text'
                        name='btnRedirection'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.btnRedirection}
                      >
                        <option value=''>
                          {t('casinoBannerManagement.constant.none')}
                        </option>
                        <option value='referAfriend'>
                          {t('casinoBannerManagement.constant.referAfriend')}
                        </option>
                        <option value='giveaway'>
                          {t('casinoBannerManagement.constant.giveaway')}
                        </option>
                        <option value='buyPage'>
                          {t('casinoBannerManagement.constant.buyPage')}
                        </option>
                        <option value='tournamentPage'>
                          {t('casinoBannerManagement.constant.tournamentPage')}
                        </option>
                        {/* <option value='personalBonus'>
                          {t('casinoBannerManagement.constant.personalBonus')}
                        </option> */}
                      </BForm.Select>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col className='d-flex'>
                      <BForm.Label>
                        Is CountDown
                      </BForm.Label>
                    </Col>
  
                    <Col xs={9}>
                      <BForm.Check
                        type='checkbox'
                        className='mx-auto'
                        name='isCountDown'
                        onChange={(e) => { handleCheckoutChange(e, setFieldValue) }}
                        onBlur={handleBlur}
                        value={values.isCountDown}
                        defaultChecked={values.isCountDown}
                      />
                    </Col>
                  </Row>
                  {values.isCountDown == true ? <>
                    <Row className='mt-3'>
                      <Col className='d-flex'>
                        <BForm.Label>
                          Start Date
                        </BForm.Label>
                      </Col>
                      <Col xs={9}>
                        <Datetime
                          inputProps={{
                            placeholder: 'MM-DD-YYYY HH:MM',
                            // disabled: details
                          }}
                          dateFormat='MM/DD/YYYY'
                          onChange={(e) => {
                            setFieldValue('startDate', e);
                            setStartDateRequired(false)
                          }}
                          value={values.startDate}
                          isValidDate={(e) => {
                            return (
                              e._d > yesterday ||
                              getDateTimeByYMD(e._d) === getDateTimeByYMD(new Date())
                            );
                          }}
                          timeFormat={true}
                        />
                        {startDateRequired && <small style={{ color: 'red' }}>Start Date Required.</small>}
                      </Col>
                    </Row>
                    <Row className='mt-3'>
                      <Col className='d-flex'>
                        <BForm.Label>
                          End Date
                        </BForm.Label>
                      </Col>
                      <Col xs={9}>
                        <Datetime
                          inputProps={{
                            placeholder: 'MM-DD-YYYY HH:MM',
                            // disabled: details
                          }}
                          dateFormat='MM/DD/YYYY'
                          onChange={(e) => {
                            setFieldValue('endDate', e);
                            setEndDateRequired(false)
                          }}
                          value={values.endDate}
                          isValidDate={(e) => {
                            return (
                              e._d > yesterday ||
                              getDateTimeByYMD(e._d) === getDateTimeByYMD(new Date())
                            );
                          }}
                          timeFormat={true}
                        />
                        {endDateRequired && <small style={{ color: 'red' }}>End Date Required.</small>}
  
                      </Col>
                    </Row>
                  </> : <></>}
  
                  <Row className='mt-3'>
                    <Col>
                      <Row>
                        <Col className='d-flex align-items-center'>
                          <Col>
                            <BForm.Label>
                              {t(
                                'casinoBannerManagement.inputField.mobileImage.label'
                              )}
                              <span className='text-danger'> *</span>
                            </BForm.Label>
                          </Col>
  
                          <Col xs={9}>
                            <BForm.Control
                              type='file'
                              name='mobileImage'
                              onChange={(event) => handleFileChange(event, setFieldValue, 'mobileImage')}
                              onBlur={handleBlur}
                            />
                            {mobileDimension ? <small style={{ color: 'red' }}>Image dimensions must be less than or equal to 684*280 pixels.</small> :
                              <> <ErrorMessage
                                component='div'
                                name='mobileImage'
                                className='text-danger'
                              />
                              </>
                            }
                          </Col>
                        </Col>
                      </Row>
                      {!errors?.mobileImage &&
                        (type === 'Create' ? (
                          values?.mobileImage && (
                            <Row className='text-center'>
                              <Col>
                                <img
                                  alt='not found'
                                  className='mt-2'
                                  style={{
                                    maxWidth: '100px',
                                    maxHeight: '100px',
                                  }}
                                  src={
                                    values?.mobileImage &&
                                    URL.createObjectURL(values?.mobileImage)
                                  }
                                />
                              </Col>
                            </Row>
                          )
                        ) : (
                          <Row className='text-center'>
                            <Col>
                              <img
                                alt='not found'
                                className='mt-2'
                                style={{ maxWidth: '100px', maxHeight: '100px' }}
                                src={
                                  values?.mobileImage
                                    ? URL.createObjectURL(values?.mobileImage)
                                    : data?.mobileImageUrl
                                }
                              />
                            </Col>
                          </Row>
                        ))}
                    </Col>
  
                    <Col>
                      <Row>
                        <Col className='d-flex align-items-center'>
                          <Col>
                            <BForm.Label>
                              {t(
                                'casinoBannerManagement.inputField.desktopImage.label'
                              )}
                              <span className='text-danger'> *</span>
                            </BForm.Label>
                          </Col>
  
                          <Col xs={9}>
                            <BForm.Control
                              type='file'
                              name='desktopImage'
                              onChange={(event) => handleFileChange(event, setFieldValue, 'desktopImage')}
                              onBlur={handleBlur}
                            />
                            {desktopDimension ? <small style={{ color: 'red' }}>Image dimensions must be less than or equal to 1200*318 pixels.</small> :
                              <>  <ErrorMessage
                                component='div'
                                name='desktopImage'
                                className='text-danger'
                              />
                              </>
                            }
                          </Col>
                        </Col>
                      </Row>
                      {!errors?.desktopImage &&
                        (type === 'Create' ? (
                          values?.desktopImage && (
                            <Row className='text-center'>
                              <Col>
                                <img
                                  alt='not found'
                                  className='mt-2'
                                  style={{
                                    maxWidth: '100px',
                                    maxHeight: '100px',
                                  }}
                                  src={
                                    values?.desktopImage &&
                                    URL.createObjectURL(values?.desktopImage)
                                  }
                                />
                              </Col>
                            </Row>
                          )
                        ) : (
                          <Row className='text-center'>
                            <Col>
                              <img
                                alt='not found'
                                className='mt-2'
                                style={{ maxWidth: '100px', maxHeight: '100px' }}
                                src={
                                  values?.desktopImage
                                    ? URL.createObjectURL(values?.desktopImage)
                                    : data?.desktopImageUrl
                                }
                              />
                            </Col>
                          </Row>
                        ))}
                    </Col>
                  </Row>
  
                  <div className='mt-4 d-flex justify-content-between align-items-center'>
                    <Button variant='warning' onClick={() => setShow(false)}>
                      {t('casinoBannerManagement.uploadBanner.cancel')}
                    </Button>
                    <Button
                      variant='success'
                      onClick={() => {
                        handleSubmit();
                        setImage(null);
                      }}
                      className='ml-2'
                    disabled={loading}
                    >
                      {t('casinoBannerManagement.uploadBanner.submit')}
                      {loading && (
                        <Spinner
                          style={{ marginLeft: '4px' }}
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditUploadBanner;

import React, { useState } from 'react';
import { Card, Row, Col } from '@themesberg/react-bootstrap';
import { Formik } from 'formik';
import { updatePackageSchema } from '../schemas';
import PackageCreateForm from './PackageCreateForm';
import useEditPackage from '../hooks/useEditPackage';
import { useNavigate } from 'react-router-dom';
import { convertToUTC, formatDateMDY, getDateTime, toCustomDateTimeFormat } from '../../../utils/dateFormatter';
import { toast } from '../../../components/Toast';
import { AdminRoutes } from '../../../routes';
import { serialize } from 'object-to-formdata';

const EditPackageDetails = () => {
  const navigate = useNavigate();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const onSuccess = (res) => {
    if (res?.data.success && (res?.data?.message || res?.data?.updatedPackage[0])) {
      navigate(AdminRoutes.Packages);
      toast(res?.data?.message, 'success', 'packageUpdate');
      // toast('Package data Updated.', 'success', 'packageUpdate');
    } else {
      toast(res?.data?.message, 'error', 'packageCreate');
    }
  };

  const onError = (error) => {
    toast(error.response.data.errors[0].description, 'error', 'packageCreate');
  };

  const {
    packageData,
    editPackage,
    packageId,
    loading,
    typeOptions,
    typeValue,
    setTypeValue,
    isSelectLoading,
    handleCreateOption,
  } = useEditPackage(onSuccess, onError);

  const handleEditPackageSubmit = (formValues) => {

    const body = {
      ...formValues,
      amount: formValues.amount.toString(),
      isActive: formValues.isActive,
      currency: 'USD',
      isVisibleInStore: formValues.isVisibleInStore,
      firstPurchaseApplicable: formValues.firstPurchaseApplicable,
      firstPurchaseScBonus: formValues.firstPurchaseApplicable ? formValues.firstPurchaseScBonus : 0,
      firstPurchaseGcBonus: formValues.firstPurchaseApplicable ? formValues.firstPurchaseGcBonus : 0,
      welcomePurchaseBonusApplicable: formValues?.welcomePurchaseBonusApplicable,
      welcomePurchaseBonusApplicableMinutes: formValues?.welcomePurchaseBonusApplicable ? formValues?.welcomePurchaseBonusApplicableMinutes : 0,
      purchaseLimitPerUser: formValues?.purchaseLimitPerUser,
      validTill: formValues.isValidUntil ? convertToUTC(formValues.validTill) : '',
      newPackageType: false,
      textColor: formValues.textColor,
      backgroundColor: formValues.backgroundColor,
      previousAmount: formValues?.previousAmount === 0 ? null : formValues?.previousAmount,
      welcomePurchasePercentage: formValues?.welcomePurchasePercentage,
      validFrom: formValues.isValidFrom ? convertToUTC(formValues.validFrom) : '',
      // validFrom: formValues.isValidFrom ? (formValues.validFrom ? convertToUTC(formValues.validFrom) : null) : null,
      bonusSc: formValues?.bonusSc || 0,
      bonusGc: formValues?.bonusGc || 0,
      playerIds: selectedUserIds,
      // filterType: selectedUserIds?.length > 0 ? (formValues?.filterType) : null,
      // filterOperator: selectedUserIds?.length > 0 ? (formValues?.filterOperator) : null,
      // filterValue: selectedUserIds?.length > 0 ? (Number(formValues?.filterValue)) : null,
      filterType:  formValues?.filterType,
      filterOperator:  formValues?.filterOperator,
      filterValue:  Number(formValues?.filterValue),
      isSpecialPackage: formValues.isSpecialPackage
    };
    if (formValues.image) {
      body.image = formValues.image;
    }
    editPackage(serialize(body));
  };

  return (
    <div>
      <Row>
        <Col sm={8}>
          <h3>Edit Package</h3>
        </Col>
      </Row>

      <Card body>
        {packageData && (
          <Formik
            initialValues={{
              packageId: +packageId,
              amount: packageData?.amount ?? '',
              gcCoin: packageData?.gcCoin ?? '',
              scCoin: packageData?.scCoin ?? '',
              isActive: packageData?.isActive,
              isVisibleInStore: packageData?.isVisibleInStore,
              firstPurchaseApplicable: packageData?.firstPurchaseApplicable ? true : false,
              firstPurchaseScBonus: packageData.firstPurchaseScBonus || 0,
              firstPurchaseGcBonus: packageData.firstPurchaseGcBonus || 0,
              isValidUntil: packageData.validTill ? true : false,
              validTill: packageData.validTill ? getDateTime(packageData.validTill) : null,
              welcomePurchaseBonusApplicable: packageData?.welcomePurchaseBonusApplicable ? true : false,
              welcomePurchaseBonusApplicableMinutes: packageData?.welcomePurchaseBonusApplicableMinutes,
              purchaseLimitPerUser: packageData?.purchaseLimitPerUser || 0,
              welcomePurchasePercentage: packageData?.welcomePurchasePercentage || 0,
              isValidFrom: packageData.validFrom ? true : false,
              validFrom: packageData.validFrom ? getDateTime(packageData.validFrom) : null,
              bonusSc: packageData?.bonusSc || 0,
              bonusGc: packageData?.bonusGc || 0,
              filterType: packageData?.moreDetails?.filterType || null,
              filterOperator: packageData?.moreDetails?.filterOperator || null,
              filterValue: Number(packageData?.moreDetails?.filterValue) || 0,
              isSpecialPackage: packageData?.isSpecialPackage
            }}
            validationSchema={updatePackageSchema}
            enableReinitialize
            onSubmit={handleEditPackageSubmit}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
              resetForm,
            }) => (
              <PackageCreateForm
                values={values}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                resetForm={resetForm}
                navigate={navigate}
                loading={loading}
                packageData={packageData}
                isEdit={!!packageData?.PackageUsers?.length}
                typeOptions={typeOptions}
                typeValue={typeValue}
                setTypeValue={setTypeValue}
                isSelectLoading={isSelectLoading}
                handleCreateOption={handleCreateOption}
                selectedUserIds={selectedUserIds}
                setSelectedUserIds={setSelectedUserIds}
              />
            )}
          </Formik>
        )}
      </Card>
    </div>
  );
};

export default EditPackageDetails;
